import { useAppNav } from '@base/components/AppNav/useAppNav'
import { useRawlplugCustomAttributes } from '@ecom/composables/useProductCustomAttributes'
import { DefaulProductQuantityValues } from '@ecom/constants/product'
import type { CartItemInterfaceExtended, CustomerCart } from '@ecom/types/types'
import usePromotions from '@ecom/composables/usePromotions'
import type {
  CartItemAvailabilityData,
  CartItemQuantityData,
  CartItemSplitData,
  PackingImage,
  ProductName,
  StockStatus,
} from './useCartProductData.types'
import type { AttributeOption } from '#gql'
import type { Maybe } from '#gql/default'

function useCartProductData() {
  /**
   * Method used for extracting and prettifying necessary cart item data.
   * @param {CartItemInterfaceExtended} cartItem
   */

  const isProductInAnyCurrentCmsPromotions = (cartItem: CartItemInterfaceExtended) => {
    const { promotions: currentPromotions } = usePromotions()

    if (!cartItem || !currentPromotions.value?.length) {
      return false
    }

    const categoriesUids = (cartItem.product?.categories ?? []).map(category => category?.uid ?? '')

    const bySku = currentPromotions.value.some(promotion => (promotion?.products ?? []).includes(cartItem.product?.sku ?? ''))

    const byCategoryUid = categoriesUids.some((element) => {
      return currentPromotions.value.some(promotion =>
        promotion.categories?.includes(element),
      )
    })

    return bySku || byCategoryUid
  }

  const getCartItem = (cartItem: CartItemInterfaceExtended) => {
    const { productLink } = useAppNav()
    const { findRawlplugAttributes } = useRawlplugCustomAttributes(
      cartItem.product?.rawlplug_attributes,
    )

    const uid = cartItem?.uid
    const productType = cartItem.product?.product_typename
    const name: ProductName = cartItem.product?.name
    const thumbnail = cartItem.product?.thumbnail?.url || ''
    const link = productLink(cartItem.product?.canonical_url || '')
    const sku = cartItem.product?.sku
    const barcode = cartItem.product?.barcode
    const customerProductCode = cartItem.product_alias ?? null

    const packagingImage: PackingImage = findRawlplugAttributes(
      'ecommerce_packing_type',
    )?.find((item: Maybe<AttributeOption>) => {
      return item?.value === cartItem.product?.ecommerce_packing_type
    })?.image

    const packagingTypeAndQuantities = findRawlplugAttributes(
      'erp_packaging_and_quantity',
    )?.find((item: Maybe<AttributeOption>) => {
      return (
        item?.value === cartItem.product?.erp_packaging_and_quantity?.toString()
      )
    })

    const brand = findRawlplugAttributes('brand')?.find(
      item => item?.value === cartItem.product?.brand,
    )

    const individualCustomerSinglePackagePrice = cartItem.prices?.price?.value
    const splitPayment = cartItem.split_payment || false

    const minimumRegularPrice
      = cartItem.product?.price_range?.minimum_price?.regular_price?.value ?? 0
    const minimumRegularPriceCurrency
      = cartItem.product?.price_range?.minimum_price?.regular_price?.currency
      ?? ''

    const minimumFinalPrice
      = cartItem.product?.price_range?.minimum_price?.final_price?.value ?? 0
    const minimumFinalPriceCurrency
      = cartItem.product?.price_range?.minimum_price?.final_price?.currency ?? ''

    const isRegularPriceGreaterThanFinal = minimumRegularPrice > minimumFinalPrice
    const hasItemAnyDiscountAssigned = (cartItem.prices?.total_item_discount?.value ?? 0) > 0

    const promotions = {
      price: isRegularPriceGreaterThanFinal || hasItemAnyDiscountAssigned,
      cms: isProductInAnyCurrentCmsPromotions(cartItem),
    }

    if (typeof individualCustomerSinglePackagePrice === 'number') {
      promotions.price = (isRegularPriceGreaterThanFinal && individualCustomerSinglePackagePrice >= minimumFinalPrice) || hasItemAnyDiscountAssigned
    }

    const productTotal = cartItem.prices?.row_total?.value

    const stockStatus: StockStatus = cartItem?.product?.stock_status ?? ''
    const leftInStockCount = cartItem?.product?.only_x_left_in_stock ?? null
    const measurementUnit = cartItem?.product?.sales_um

    const availabilityData = {
      status: cartItem?.availability_data?.status,
      label: cartItem?.availability_data?.label ?? '',
      quantity: cartItem?.availability_data?.qty,
    } as CartItemAvailabilityData

    // Product quantities, intervals etc
    const quantity = {
      value: cartItem?.quantity,
      min:
        cartItem?.stock_item?.min_sale_qty || DefaulProductQuantityValues.min,
      max:
        cartItem?.stock_item?.max_sale_qty || DefaulProductQuantityValues.max,
      step:
        cartItem?.stock_item?.qty_increments
        || DefaulProductQuantityValues.step,
    } as CartItemQuantityData

    return {
      uid,
      productType,
      name,
      thumbnail,
      link,
      sku,
      barcode,
      customerProductCode,
      brand,
      individualCustomerSinglePackagePrice,
      splitPayment,
      measurementUnit,
      minimumRegularPrice,
      minimumRegularPriceCurrency,
      minimumFinalPrice,
      minimumFinalPriceCurrency,
      promotions,
      productTotal,
      stockStatus,
      leftInStockCount,
      availabilityData,
      quantity,
      packagingImage,
      packagingTypeAndQuantities,
    }
  }

  /**
   * Method used for extracting and prettifying necessary summary cart data
   * @param {CustomerCart} cart
   */
  const getCartBeforeOrderSummary = (cart: CustomerCart) => {
    // VAT
    const appliedTaxes = {
      value: cart?.prices?.applied_taxes?.[0]?.amount?.value ?? 0,
      currency: cart?.prices?.applied_taxes?.[0]?.amount?.currency ?? '',
    }

    // SUBTOTAL NET VALUE
    const subtotalExcludingTax = {
      value: cart?.prices?.subtotal_excluding_tax?.value ?? 0,
      currency: cart?.prices?.subtotal_excluding_tax?.currency ?? '',
    }

    //  SUBTOTAL NET VALUE WITH DISCOUNT
    const subtotalWithDiscountExcludingTax = {
      value: cart?.prices?.subtotal_with_discount_excluding_tax?.value ?? 0,
      currency:
        cart?.prices?.subtotal_with_discount_excluding_tax?.currency ?? '',
    }

    const discounts
      = cart?.prices?.discounts?.map?.(discount => ({
        label: discount?.label ?? '',
        value: discount?.amount?.value ?? 0,
      })) ?? []

    // SUBTOTAL GROSS AMOUNT
    const subtotalIncludingTax = {
      value: cart?.prices?.subtotal_including_tax?.value ?? 0,
      currency: cart?.prices?.subtotal_including_tax?.currency ?? '',
    }

    // SHIPPING COST USED BEFORE CART DIVISION, USED AS A FALLBACK
    const selectedShippingMethod = cart?.shipping_addresses?.[0]?.selected_shipping_method ?? null

    // SHIPPING COSTS FOR AVAILABLE AND NOT AVAILABLE PRODUCTS
    const splitData: CartItemSplitData = {
      /* null value can appear when there are no available/unavailable products in cart.
      In all other cases there will be a number - when shippign is free, there will be number 0 */
      availableShippingCost: cart?.split_data?.available_shipping_cost ?? null,
      notAvailableShippingCost:
        cart?.split_data?.not_available_shipping_cost ?? null,
      availableAmountToFreeShipping:
        cart?.split_data?.available_amount_to_free_shipping ?? null,
      notAvailableAmountToFreeShipping:
        cart?.split_data?.not_available_amount_to_free_shipping ?? null,
      availableSubtotal: cart?.split_data?.available_subtotal ?? null,
      notAvailableSubtotal: cart?.split_data?.not_available_subtotal ?? null,
      vouchersSubtotal: cart?.split_data?.vouchers_subtotal ?? null,
    }

    const grandTotal = {
      value: cart?.prices?.grand_total?.value ?? 0,
      currency: cart?.prices?.grand_total?.currency ?? '',
    }

    return {
      appliedTaxes,
      subtotalExcludingTax,
      subtotalWithDiscountExcludingTax,
      discounts,
      subtotalIncludingTax,
      selectedShippingMethod,
      grandTotal,
      splitData,
    }
  }

  const isAvailableQuantityMoreThanZero = (availableQuantity: number) => {
    return !(
      availableQuantity == null
      || (typeof availableQuantity === 'number' && availableQuantity <= 0)
    )
  }

  return {
    getCartItem,
    getCartBeforeOrderSummary,
    isAvailableQuantityMoreThanZero,
  }
}

export { useCartProductData }
